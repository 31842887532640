<template>
  <v-card
    id="withdraw-note-list"
    class="px-3 py-5">
    <v-row>
      <v-col cols="auto">
        <h6 class="text-h6">
          Withdraw Notes
        </h6>
      </v-col>
      <v-spacer />
      <v-col cols="auto">
        <v-btn
          :to="{
            name: 'WithdrawNote',
            query: {
              warehouse: warehouseSelected
            }
          }"
          target="_blank"
          color="primary"
          :disabled="isDisabled || isDisabledByRole">
          Create Note
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="isMobile ? '6' : 'auto'">
        <WarehouseSelect
          v-model="warehouseSelected"
          :items="warehouses"
          item-text="name"
          item-value="id"
          :menu-props="{ offsetY: true }"
          label="Warehouses"
          outlined />
      </v-col>
      <v-col :cols="isMobile ? '6' : 'auto'">
        <v-select
          v-model="operation"
          :items="operationItems"
          :menu-props="{ offsetY: true }"
          :class="isMobile ? '' : 'movement-operation'"
          auto-select-first
          hide-details
          solo
          dense
          @change="checkOperation()" />
      </v-col>
      <v-col
        v-if="enableDateRange"
        cols="auto">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-row>
              <v-col cols="auto">
                <v-text-field
                  v-model="dateRangeText[0]"
                  label="Start Date"
                  prepend-icon="mdi-calendar"
                  class="date-range-input mr-2"
                  max-width="290px"
                  readonly
                  hide-details
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on" />
              </v-col>
              <v-col cols="auto">
                <v-text-field
                  v-model="dateRangeText[1]"
                  label="End Date"
                  class="date-range-input"
                  max-width="290px"
                  readonly
                  hide-details
                  outlined
                  dense
                  v-bind="attrs"
                  v-on="on" />
              </v-col>
            </v-row>
          </template>
          <v-date-picker
            v-model="dates[0]"
            class="mr-2 elevation-3"
            :max="$dayjs(new Date()).format('YYYY-MM-DD')"
            @input="checkOperation()" />
          <v-date-picker
            v-model="dates[1]"
            :max="$dayjs(new Date()).format('YYYY-MM-DD')"
            @input="checkOperation()" />
        </v-menu>
      </v-col>
      <v-spacer />
      <v-col :cols="isMobile ? '12' : 'auto'">
        <search-box
          v-model="searchInput"
          :disabled="getting"
          @on-search="onSearch()" />
      </v-col>
    </v-row>
    <v-row
      class="align-center">
      <v-col>
        <v-tabs
          v-model="tabs"
          show-arrows
          class="d-flex"
          @change="changeTab($event)">
          <v-tab
            v-for="(tabTitle, index) in tabsTitle"
            :key="`tabTitle-${index}`">
            <span class="mr-1">
              {{ tabTitle.label }} |
            </span>
            <span
              v-if="loadingCount"
              class="count-tab">
              <v-progress-circular
                indeterminate
                size="16" />
            </span>
            <span v-else>
              {{ tabTitle.count }}
            </span>
          </v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="auto">
        <div
          class="d-flex justify-end align-center">
          <v-btn
            v-if="tabsTitle[tabs].label != 'canceled' && tabsTitle[tabs].label != 'draft'"
            class="mx-1"
            :disabled="withdrawNoteSelected.length <= 0"
            small
            @click="print()">
            <v-icon
              class="mr-2">
              mdi-printer
            </v-icon>
            <span>Print ( {{ withdrawNoteSelected.length }} )</span>
          </v-btn>
        </div>
      </v-col>
      <v-col :cols="12">
        <delivery-and-withdraw-notes-table
          to="WithdrawNote"
          :height="500"
          :loading="getting"
          :note-data="withdrawNotesData"
          :status="tabsTitle[tabs].label"
          :warehouse-id="warehouseSelected"
          :type="'WithdrawNote'"
          :warehouse="warehouse"
          :is-mobile="isMobile"
          :change-tab-delivery-note="changeTabWithdrawNote"
          @cancel="tryToUpdateNote"
          @renew="renewWithdrawNote"
          @scroll="handleScroll"
          @select-delivery-and-withdraw="selectWithdrawNote" />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { getAuthDecode, getRole } from '@/assets/js/Authentication'
import { mapActions, mapGetters } from 'vuex'
import DeliveryAndWithdrawNotesTable from '@/views/inventory/components/DeliveryAndWithdrawNotesTable.vue'
import SearchBox from '@/components/SearchBox.vue'
import WarehouseSelect from '@/components/WarehouseSelect.vue'
import InventoryProvider from '@/resources/InventoryProvider'

const InventoryService = new InventoryProvider()

export default {
  components: {
    deliveryAndWithdrawNotesTable: DeliveryAndWithdrawNotesTable,
    SearchBox,
    WarehouseSelect
  },
  data () {
    return {
      tabsTitle: [
        {
          label: 'pending',
          count: 0
        },
        {
          label: 'approved',
          count: 0
        },
        {
          label: 'canceled',
          count: 0
        },
        {
          label: 'draft',
          count: 0
        }
      ],
      operationItems: [
        'All',
        'Period'
      ],
      operation: 'All',
      searchInput: '',
      dates: [
        this.$dayjs().subtract(7, 'd').format('YYYY-MM-DD'),
        this.$dayjs().format('YYYY-MM-DD')
      ],
      menu: false,
      tabs: 0,
      page: 0,
      perPage: 15,
      totalPage: 1,
      getting: false,
      isLoadComplete: false,
      withdrawNotesData: [],
      warehouseSelected: null,
      noteStatus: '',
      loadingCount: false,
      withdrawNoteSelected: [],
      changeTabWithdrawNote: false
    }
  },
  computed: {
    ...mapGetters({
      warehouse: 'Store/warehouse',
      mapWarehouse: 'Store/mapWarehouse'
    }),
    enableDateRange () {
      return this.operation === 'Period'
    },
    dateRangeText () {
      return this.sortDate()
    },
    startDate () {
      if (this.enableDateRange) {
        const dates = this.sortDate()
        return dates[0] ? new Date(parseInt(this.$dayjs(dates[0], 'DD MMM YYYY').format('x'))) : null
      }
      return null
    },
    endDate () {
      if (this.enableDateRange) {
        const dates = this.sortDate()
        return dates[1] ? new Date(parseInt(this.$dayjs(dates[1], 'DD MMM YYYY').add(1, 'day').subtract(1, 'millisecond').format('x'))) : null
      }
      return null
    },
    isDisabledByRole () {
      const dnRole = [
        'management',
        'area_manager',
        'marketing',
        'developer',
        'creative',
        'merchandising_planner',
        'merchandiser',
        'online_admin',
        'accounting_manager',
        'warehouse_manager',
        'warehouse',
        'store_manager',
        'vm',
        'acting_assist_store_manager',
        'inventory_and_costing',
        'assist_store_manager',
        'sales_staff'
      ]
      const role = getRole()
      return !dnRole.some((r) => r === role)
    },
    isDisabled () {
      return this.warehouseSelected === 0
    },
    isMobile () {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm
    },
    warehouses () {
      return this.mapWarehouse.filter((v) => v.id !== 0)
    }
  },
  watch: {
    warehouseSelected () {
      this.resetFetchWithdrawNote(true)
    },
    warehouses (val) {
      if (!this.warehouseSelected && val.length >= 1) {
        this.warehouseSelected = this.warehouses[0].id
      }
    }
  },
  mounted () {
    if (!this.warehouseSelected && this.warehouses.length >= 1) {
      this.warehouseSelected = this.warehouses[0].id
    }

    this.noteStatus = this.tabsTitle[0].label
  },
  methods: {
    ...mapActions({
      setErrorPage: 'Components/setErrorPage'
    }),
    selectWithdrawNote (event) {
      this.withdrawNoteSelected = event
    },
    print () {
      const id = []
      const warehouse = []
      this.withdrawNoteSelected.forEach((item) => {
        id.push(item.id)
        warehouse.push(item.warehouse.id)
      })

      window.open(
        this.$router.resolve({
          name: 'MultiWithdrawNote',
          query: {
            id,
            warehouse
          }
        }).href,
        '_blank'
      )
    },
    handleScroll (event) {
      const e = event.target
      const scrollY = e.scrollHeight - e.clientHeight - 100
      if (e.scrollTop >= scrollY && !this.isLoadComplete && !this.getting) {
        this.fetchData()
      }
    },
    checkOperation () {
      if (this.operation === 'All') {
        this.resetFetchWithdrawNote()
      } else if (this.operation === 'Period' && this.startDate && this.endDate) {
        this.resetFetchWithdrawNote()
      }
    },
    async renewWithdrawNote (index) {
      const withdrawNote = this.withdrawNotesData[index]
      let isDone = false
      try {
        this.withdrawNotesData.splice(index, 1, {
          ...withdrawNote,
          loading: true
        })
        const WithdrawNoteById = await InventoryService.getWithdrawNoteById(withdrawNote.id)
        const createNote = await InventoryService.createWithdrawNotes({
          warehouse: WithdrawNoteById.data.warehouse,
          products: WithdrawNoteById.data.products,
          note: `Renew (${withdrawNote.code}) by ${getAuthDecode().name}`,
          status: 'draft'
        })
        isDone = createNote.data.status === 'draft'
      } catch (error) {
        console.error('renewWithdrawNote', error)
      } finally {
        if (isDone) {
          await this.fetchCount()
        }
        this.withdrawNotesData.splice(index, 1, {
          ...withdrawNote
        })
      }
    },
    async tryToUpdateNote (index) {
      this.$store.dispatch('Components/setModal', {
        value: true,
        title: `ยืนยันการยกเลิก ${this.withdrawNotesData[index].code}`,
        message: 'การยกเลิกใบนำสินค้าออก(WN) โปรดตรวจสอบให้ถูกต้องก่อนการกดยกเลิก (ประวัติของคุณจะถูกเก็บไว้ในระบบ)',
        confirmText: 'Cancel',
        confirmType: 'error',
        cancelType: '',
        cancelText: 'Back',
        onConfirm: () => this.cancelWithdrawNote(index)
      })
    },
    async cancelWithdrawNote (index) {
      const withdrawNote = this.withdrawNotesData[index]
      let isDone = false
      try {
        this.withdrawNotesData.splice(index, 1, {
          ...withdrawNote,
          loading: true
        })
        const { data } = await InventoryService.updateStatusWithdrawNotes({
          id: withdrawNote.id,
          status: 'cancel'
        })

        isDone = data.status === 'canceled'

        // if (withdrawNote?.deliveryNoteRef?.id) {
        //   await InventoryService.updateStatusDeliveryNotes({
        //     id: withdrawNote.deliveryNoteRef.id,
        //     status: 'cancel'
        //   })
        // }
      } catch (error) {
        console.error('cancelWithdrawNote', error)
      } finally {
        if (isDone) {
          await this.fetchCount()
          this.withdrawNotesData.splice(index, 1)
        } else {
          this.withdrawNotesData.splice(index, 1, {
            ...withdrawNote
          })
        }
      }
    },
    sortDate () {
      const dates = []
      this.dates = this.dates.sort((a, b) => (this.$dayjs(a, 'YYYY-MM-DD').format('x') - this.$dayjs(b, 'YYYY-MM-DD').format('x')))
      for (let i = 0; i < this.dates.length; i++) {
        dates.push(this.$dayjs(this.dates[i], 'YYYY-MM-DD').format('DD MMM YYYY'))
      }
      return dates
    },
    fetchData () {
      this.fetchCount()
      this.fetchWithdrawNote()
    },
    async fetchCount () {
      try {
        const { data } = await InventoryService.getWithdrawNotesCount({
          search: this.searchInput,
          warehouse: this.warehouseSelected,
          startDate: this.startDate,
          endDate: this.endDate
        })
        for (let i = 0; i < this.tabsTitle.length; i++) {
          this.tabsTitle[i].count = data[this.tabsTitle[i].label]
        }
      } catch (error) {
        console.error('fetchCount', error)
      } finally {
        this.loadingCount = false
      }
    },
    changeTab (index) {
      this.noteStatus = this.tabsTitle[index].label
      this.resetFetchWithdrawNote()
    },
    resetFetchWithdrawNote (loadingCount = false) {
      this.loadingCount = loadingCount
      this.getting = false
      this.page = 0
      this.totalPage = 1
      this.isLoadComplete = false
      this.withdrawNotesData = []
      this.changeTabWithdrawNote = !this.changeTabWithdrawNote
      this.fetchData()
    },
    async fetchWithdrawNote () {
      this.getting = true
      if (this.page < this.totalPage) {
        this.page++
        try {
          const { data } = await InventoryService.getWithdrawNotes({
            page: this.page,
            limit: this.perPage,
            search: this.searchInput,
            status: this.noteStatus,
            warehouse: this.warehouseSelected,
            startDate: this.startDate,
            endDate: this.endDate,
            sortBy: '_id',
            sortOrder: 'desc'
          })
          this.totalPage = data.pages
          this.withdrawNotesData.push(...data.results)
        } catch (error) {
          console.error('fetchWithdrawNote', error)
          this.setErrorPage(error.code)
        } finally {
          this.isLoadComplete = this.page >= this.totalPage
          this.getting = false
        }
      }
    },
    onSearch () {
      this.resetFetchWithdrawNote()
    }
  }
}
</script>

<style lang="scss" scoped>
#stock-movement {
  height: calc(100vh - 68px);
}
.movement-operation {
  width: 110px;
}
.date-range-input {
  width: 255px;
}
</style>
